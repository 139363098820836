/**
 * 经纪人管理-经纪人统计
 * luxinwen
 * 2023-04-26
 */
 <style>
  .count_title {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
  }
</style>
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="['OSP_BROKER_STATISTICS_QUERY', 'OSP_BROKER_STATISTICS_EXPORT']">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.nickname" placeholder="请输入微信昵称" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.mobile" placeholder="请输入手机号" clearable />
          </FormItem>
          <FormItem>
            <DatePicker class="width-m" v-model="formData.registerTime" type="datetimerange" placeholder="请选择授权登录时间" />
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData" v-grant="'OSP_BROKER_STATISTICS_QUERY'">查询</Button>
            <Button type="primary" @click="exportData" v-grant="'OSP_BROKER_STATISTICS_EXPORT'">导出</Button>
            <Button @click="showRecord" v-grant="'OSP_BROKER_STATISTICS_EXPORT'">查看导出记录</Button>
          </FormItem>
        </Form>
      </div>
      <div class="count_title">会员总人数 {{pager.total}}人</div>
      <div class="page-main-content">
        <sp-table :columns="tableColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
        </sp-table>
      </div>
    </div>
    <Modal v-model="recordDisplay" width="80%" title="导出记录" footer-hide :mask-closable="false">
      <sp-table :columns="recordColumns" :data="recordDataList" :loading="recordLoading" :total="recordPager.total" :page="recordPager.pageNum" :page-size="recordPager.pageSize" @change="changeRecordPage">
      </sp-table>
    </Modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        recordDisplay: false,
        recordColumns: [
          {
            type: 'index',
            title: '序号',
            width: 100,
            align: 'center'
          },
          {
            title: '文件名',
            key: 'fileName'
          },
          {
            title: '操作人',
            key: 'operator'
          },
          {
            title: '导出时间',
            key: 'createdTime'
          },
          {
            title: '操作',
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h('a', {
                  attrs: {
                    href: params.row.downloadUrl,
                    target: '_blank'
                  }
                }, '请下载')
              ]);
            }
          }
        ],
        recordDataList: [],
        recordLoading: false,
        recordPager: {
          total: 0,
          pageNum: 1,
          pageSize: 10
        },
        formData: {
          nickname: '', // 昵称
          mobile: '', // 手机号
          registerTime: []
        },
        tableColumns: [
          {
            title: '序号',
            type: 'index',
            width: 70,
            align: 'center'
          },
          {
            title: '微信昵称',
            key: 'nickname'
          },
          {
            title: '手机号',
            key: 'mobile'
          },
          {
            title: '是否经纪人',
            key: 'certifyFlag',
            render: (h, params) => {
              return h('Tag', {
                props: {
                  color: params.row.certifyFlag ? 'success' : 'default'
                }
              }, ['否', '是'][params.row.certifyFlag]);
            }
          },
          {
            title: '授权登录时间',
            key: 'registerTime',
            render: (h, params) => {
              return h('span', this.formatDate(params.row.registerTime));
            }
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
      };
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        if (this.formData.registerTime.length > 1) {
          data.registerStartTime = this.formatDate(this.formData.registerTime[0]);
          data.registerEndTime = this.formatDate(this.formData.registerTime[1]);
        }
        delete data.registerTime;
        this.$axios({
          url: this.$api.member.queryMemberListPage,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查看导出记录
       */
      showRecord() {
        this.recordDisplay = true;
        this.getRecordData();
      },
      /**
       * 导出
       */
      exportData() {
        let data = Object.assign({}, this.formData, {});
        if (this.formData.registerTime.length > 1) {
          data.registerStartTime = this.formatDate(this.formData.registerTime[0]);
          data.registerEndTime = this.formatDate(this.formData.registerTime[1]);
        }
        delete data.registerTime;
        this.$axios({
          url: this.$api.member.exportMemberListPage,
          data,
          loading: true
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '操作成功，请稍后查看导出记录'
          });
        });
      },
      /**
       * 获取导出记录数据
       */
      getRecordData() {
        let params = {
          operationType: 'MEMBER_LIST',
          pageNum: this.recordPager.pageNum,
          pageSize: this.recordPager.pageSize
        };
        this.$axios({
          url: this.$api.analysis.exportRecordList,
          data: params,
          beforeSend: () => {
            this.recordLoading = true;
          },
          complete: () => {
            this.recordLoading = false;
          }
        }).then(data => {
          this.recordDataList = data.list || [];
          this.recordPager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 切换页码或每条页数
       */
      changeRecordPage(pager) {
        this.recordPager.pageNum = pager.page;
        this.recordPager.pageSize = pager.pageSize;
        this.getRecordData();
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>